import React from "react"
import "../stylesheets/templateresults.css"
import {Link} from "gatsby"
import {
  Content,
  FeaturedImage,
  FrontMatter,
  Layout,
  Subscribe,
} from "../components"
import {P } from "../elements"
import SEO from "../components/SEO"
import ParseHTML from "../utils/ParseHTML"
const SingleResultTemplate = ({ pageContext }) => {
  const { title, date, author, featured_media, content } = pageContext

  return (
    <Layout backgroundColor="light" hasHeader={false}>
      <SEO title={title} description={content} />
      <FeaturedImage fluid={featured_media.localFile.childImageSharp.fluid} />
      <Content>
        <Link to="/results"><P margin="0 0 26px 0" black="black">&#8249; Go Back</P></Link>
        <FrontMatter title={title} author={author} date={date} />
        {ParseHTML(content)}
      </Content>
      <Subscribe />
    </Layout>
  )
}
export default SingleResultTemplate
